#launchContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#launchContainer > div {
  text-align: center;
}
#playBtn{
  background-color: #ffec00;
  color: black;
}
